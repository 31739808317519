import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Home = () => {
	const { t } = useTranslation('common');
	const [state, setState] = useState([]);

	useEffect(() => {
		axios.get('/api/faq').then((res) => setState(res.data));
	}, []);

	return (
		<>
			<Helmet>
				<title>{t('faq.title')}</title>
			</Helmet>
			<h2>{t('faq.content')}</h2>
			<ul>
				{state.map((item) => (
					<li>{item.question_text}</li>
				))}
			</ul>
		</>
	);
};

export default Home;
