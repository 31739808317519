import 'react-app-polyfill/ie11'; // Do not change location - needed for IE11 compatibility
import 'react-app-polyfill/stable'; // Do not change location - needed for IE11 compatibility

import { I18nextProvider } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import React from 'react';
import ReactDOM from 'react-dom';

import i18next from 'i18next';
import * as serviceWorker from './serviceWorker';
import App from './App.jsx';
import commonDe from './translations/de/common.json';
import commonEn from './translations/en/common.json';

i18next.use(LanguageDetector).init({
	fallbackLng: 'en',
	ns: ['translations'],
	defaultNS: 'translations',
	load: 'languageOnly',
	debug: false,
	detection: {
		order: ['path', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],
	},
	interpolation: {
		escapeValue: false,
	},
	react: {
		wait: true,
		defaultTransParent: 'div',
	},
	resources: {
		en: {
			common: commonEn, // 'common' is our custom namespace
		},
		de: {
			common: commonDe,
		},
	},
});

ReactDOM.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18next}>
			<App />
		</I18nextProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

serviceWorker.register();
