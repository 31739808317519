import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React from 'react';

const Home = () => {
	const { t } = useTranslation('common');
	return (
		<>
			<Helmet>
				<title>{t('home.title')}</title>
			</Helmet>
			<h2>{t('home.content')}</h2>
		</>
	);
};

export default Home;
