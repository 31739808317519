import { Link, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import React from 'react';

import { Faq, Home } from './pages';

const App = () => {
	const { t, i18n } = useTranslation('common');

	const getLanguage =
		i18n.language.substring(0, 2) ||
		window.localStorage.i18nextLng.substring(0, 2);

	if (
		!window.location.pathname.substring(0, 3).includes(`/${getLanguage}`) &&
		getLanguage !== 'en'
	) {
		window.location.href = `/${getLanguage}`;
	}

	return (
		<Router>
			<div>
				<nav>
					<ul>
						<li>
							<Link to={getLanguage === 'en' ? '/' : `/${getLanguage}`}>
								{t('home.title')}
							</Link>
						</li>
						<li>
							<Link to={`/${getLanguage}/faq`}>{t('faq.title')}</Link>
						</li>
					</ul>
				</nav>

				<Switch>
					<Route path={['/:lang', '/']} exact>
						<Home />
					</Route>
					<Route path="/:lang/faq" exact>
						<Faq />
					</Route>
				</Switch>
			</div>
		</Router>
	);
};

export default App;
